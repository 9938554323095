<template>
  <Actions :actions="actions" />
</template>


<script>
import Actions from "@/components/Actions";
import { computed } from "vue";
import { useStore } from "vuex";
export default {
  components: {
    Actions,
  },
  setup() {
    const store = useStore();
    const actions = computed(() => store.getters["action/completedActions"]);
    return {
      actions,
    };
  },
};
</script>
